import { startTransition } from 'react';
//@ts-ignore
import { ProgressBar, ProgressBarProvider, useProgress } from 'react-transition-progress';
//@ts-ignore
import { Link } from 'react-transition-progress/next';

const useTransitionProgress = () => {
  const startProgress = useProgress();

  return (callback: () => void) =>
    startTransition(() => {
      startProgress();
      callback();
    });
};

export { ProgressBar, ProgressBarProvider, Link, useTransitionProgress };
