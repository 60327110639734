import React, { JSX, LegacyRef, PropsWithChildren } from 'react';

const GridContainer = (
  props: PropsWithChildren<{
    className?: string;
    containerRef?: LegacyRef<HTMLDivElement>;
    id?: string;
  }>
): JSX.Element => {
  const { children, className, containerRef, id } = props;

  return (
    <div id={id || ''} ref={containerRef} className={`grid-section ${className || ''}`}>
      <div className="max-width">{children}</div>
    </div>
  );
};

export default GridContainer;
